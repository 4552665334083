/* map sizes */
@media (min-width: 768px) {
  #map-canvas {
    height: 640px;
  }
}
@media (max-width: 767px) and (max-height: 320px) {
  #map-canvas {
    height: 160px;
  }
}
@media (max-width: 767px) and (min-height: 321px) and (max-height: 480px) {
  #map-canvas {
    height: 240px;
  }
}
@media (max-width: 767px) and (min-height: 481px) {
  #map-canvas {
    height: 320px;
  }
}

/* logo sizing */
.navbar-brand {
  padding: 10px;
}
.navbar-brand > img {
  height: 30px;
}

/* progress bar */
#progressBar {
  position: relative;
  height: 48px;
  margin-bottom: 10px;
}
#progressBar > .line {
  position: absolute;
  top: 50%;
  left: 10%;
  height: 8px;
  margin-top: -4px;
  border-radius: 4px;
}
#progressBar > .line.backdropLine {
  right: 10%;
  background-color: #ddd;
}
#progressBar > .line.activeLine {
  background-color: #7b4;
}
#progressBar > .line.activeLine.step0 { right: 70% }
#progressBar > .line.activeLine.step1 { right: 30% }
#progressBar > .line.activeLine.step2 { right: 0% }
#progressBar > .beginningCircle {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  background-color: #7b4;
  border-radius: 12px;
}
#progressBar > .icon {
  display: inline-block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  border-radius: 24px;
  border: 2px solid white;
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  font-size: 24px;
  text-align: center;
}
#progressBar > .icon > .fa {
  vertical-align: middle;
  line-height: 1.75;
}
#progressBar > .icon.progressIcon {
  background-color: #eee;
}
#progressBar > .icon.progressIcon.activeIcon {
  background-color: #7b4;
  color: white;
}
#progressBar > .icon.packageIcon { left: 50% }
#progressBar > .icon.destinationIcon { left: 90% }
#progressBar > .icon.bicycleIcon {
  background-color: #08c;
  color: white;
}
#progressBar > .icon.bicycleIcon.step0 { left: 30% }
#progressBar > .icon.bicycleIcon.step1 { left: 70% }
#progressBar > .icon.bicycleIcon.step2 { left: 95% }

p {
  word-wrap: break-word;
}